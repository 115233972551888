@import '@scss/_module';

.progress_holder {
  overflow: hidden;
}

.progress_bar {
  display: block;
  height: rem(8);
  overflow: hidden;
  position: relative;

  div {
    height: 100%;
    background-color: $progress-bg-accent;
  }
}
