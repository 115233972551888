@import '@scss/_module';

.join_progress {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(170.43deg, #1f2730 0%, #151c24 99.39%);

  @include media-breakpoint-up(lg) {
    height: 100%;
  }
}

.logo {
  width: rem(314);
  height: rem(422);
}

.bg_logo {
  position: absolute;
  bottom: 0;
  right: 0;
  width: rem(314);
  height: rem(422);
}

.content {
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  * {
    color: #fff;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: rem(40) rem(60) rem(20) rem(60);
  text-align: center;
  background: rgba(0, 0, 0, 0.15);
  height: rem(200);
  color: #d5dfe6;
}

.progress_chart {
  position: relative;
  width: rem(200);
  height: rem(200);
  margin: rem(20) auto;
}

.step_info {
  width: rem(300);
}

.progress_percent {
  position: absolute;
  top: rem(57);
  left: 0;
  right: 0;
  font-size: rem(50);
  font-weight: 600;
}

.progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress_step {
  position: relative;
  margin-top: rem(20);
  height: rem(50);
  text-align: center;
}

.progress_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: rem(30);
  height: rem(30);
  background: #2c333c;
  border: 1px solid #7f8b96;

  &.active {
    background: #008feb;
    border: none;
    font-weight: bold;
  }
}

.step_title {
  position: absolute;
  bottom: rem(-5);
  left: rem(-25);
  right: rem(-25);
  text-align: center;
  font-weight: 600;
  white-space: nowrap;

  @media (min-width: 992px) and (max-width: 1190px){
    word-wrap: break-word;
    width: rem(50);
    left: rem(-10);
    right: rem(-10);
    white-space: break-spaces;
    bottom: rem(-18);
    height: rem(36);
  }

  @media only screen and (max-width: 510px) {
    word-wrap: break-word;
    width: rem(50);
    left: rem(-10);
    right: rem(-10);
    white-space: break-spaces;
    bottom: rem(-18);
    height: rem(36);
  }

  @media only screen and (max-width: 410px) {
    word-wrap: break-word;
    width: rem(40);
    left: rem(-10);
    right: rem(-10);
    white-space: break-spaces;
    bottom: rem(-18);
    height: rem(36);
  }
}

.progress_delimiter {
  width: rem(30);
  border-top: 1px dashed #d5dfe6;
}

.footer_summary {
  font-family: $font-alt;
  color: $white;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
