@import '@scss/_module';

$loader-size: 72px;

.loader {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.lds_ripple {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $loader-size;
  height: $loader-size;
  margin-top: -$loader-size/2;
  margin-left: -$loader-size/2;

  div {
    position: absolute;
    border: 4px solid #009BFF;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
