@import '@scss/_module';

.selected_item {
  margin-right: 4px;
  margin-bottom: 4px;
  background: $blacksqueeze;
  border: 1px solid #D5DFE6;


  > div {
    color: $black;
  }
}

.add_more {
  padding-top: 3px;
  padding-bottom: 3px;
  height: rem(28);
}