.react-tel-input {
  padding: 0;
  display: flex;
  align-items: center;
  border: 0;

  > input[type='tel'].form-control {
    border: 0;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    height: auto;
    width: 100%;
    line-height: 1.43;
    border-radius: 5px;
    border: 1px solid $input-border-color;
    outline: none !important;

    @include media-breakpoint-up(md) {
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
    }
  }

  > div.flag-dropdown {
    background: transparent;
    border: 0;
    left: 4px;

    &:hover {
      .selected-flag {
        background: transparent;
      }
    }
  }

  > div.flag-dropdown.open-dropdown {
    background: transparent;

    .selected-flag {
      background: transparent;
    }

    &:hover {
      .selected-flag {
        background: transparent;
      }
    }
  }
}
