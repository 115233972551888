@import '@scss/_module';

.wrapper {
  padding: rem(8);
  cursor: default;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: rem(14);
  font-weight: 600;
  border: 1px solid $geyser;
  border-radius: 4px;
  text-transform: capitalize;

  &.selected {
    border-color: $blue;
  }
}

.icon {
  margin-right: rem(10);
}
