@import '@scss/_module';

.wrapper {
  background: #FFFFFF;
  border: 1px solid #D5DFE6;
  border-radius: 4px;
  color: #566068;
  font-size: 13px;
  line-height: 13px;
}

.skill_name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.year_input {
  width: rem(34);
  height: rem(28);
}

.icon {
  stroke: #7F8B96;
}

.label {
  width: rem(80);
  margin-right: rem(5);
  padding-bottom: rem(10);
}