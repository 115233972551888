// cssnano produces improper output in postcss-normalize-positions
// clean-css
// this is a temp fix to avoid the issue where
// input:
// background-position: center right $input-height-inner-quarter;
// produces output:
// background-position: 100% $input-height-inner-quarter;

// helpers

.w-520 {
  width: rem(520)
}

.mw-150 {
  min-width: rem(150);

  &.btn {
    max-width: rem(150);
    padding-left: rem(10);
    padding-right: rem(10);
    white-space: nowrap;
  }
}

// header fix
.header-fixed .app-header {
  z-index: 99;
}

// modal
.modal-dialog {
  // max-width: 500px;
  margin: 5em auto;
}

// margins
.mlr-sm {
  margin-left: -$container-gap;
  margin-right: -$container-gap;
}

.plr-sm {
  padding-left: $container-gap;
  padding-right: $container-gap;
}

.mtb-25 {
  margin-top: rem(25);
  margin-bottom: rem(25);
}

.mb-7 {
  margin-bottom: rem(7);
}

.mb-14 {
  margin-bottom: rem(14);
}

.mb-15 {
  margin-bottom: rem(15);
}

.mb-16 {
  margin-bottom: rem(16);
}

.mt-20 {
  margin-top: rem(20);
}

.ml-10 {
  margin-left: rem(10);
}

.mb-24,
p.mb-24 {
  margin-bottom: rem(24);
}

.fw-600 {
  font-weight: 600;
}

.fs-12 {
  font-size: rem(12);
  line-height: rem(20);
}

.fs-24 {
  margin-bottom: rem(24);
}

.ml-20 {
  margin-left: rem(20) !important;
}

.ml-12 {
  margin-left: rem(12);
}

.mr-10 {
  margin-right: rem(10);
}

.mr-12 {
  margin-right: rem(12);
}

.ml-ch-10 > * {
  margin-left: rem(10);
}

.ml-ch-6 > * {
  margin-left: rem(6);
}

.mr-70 {
  @media screen and (min-width: 981px) {
    margin-right: rem(16);
  }

  @media screen and (min-width: 1300px) {
    margin-right: rem(16);
  }
}

.w-130 {
  width: rem(130);
}

// font sizes
.fs-10 {
  font-size: rem(10);
  line-height: 1.6;
  font-family: $font-alt;
}

.fs-14 {
  font-size: rem(14);
}

.fs-16 {
  font-size: rem(16);
  line-height: 1.5;
}

.fs-18 {
  font-size: rem(18);
  line-height: 1.333334;
}

.ff-avenir {
  font-family: $font-base;
}

.help-text {
  color: $regentgray;
  font-weight: 600;
  font-family: $font-base;
  text-align: center;
}

// paddings
.plr-30 {
  padding-left: rem(30);
  padding-right: rem(30);
}

.ptb-25 {
  padding-top: rem(25);
  padding-bottom: rem(25);
}

.pl-24 {
  padding-left: rem(24);
}

.p-sm {
  padding: rem(25) $container-gap;
}

.pb-100 {
  padding-bottom: rem(100);
}

.pt-48 {
  padding-top: rem(48);
}

// get_more
.get_more {
  color: $regentgray;
  font-weight: 500;
}

// bgs
.bg-white {
  background-color: $white;
}

$gap-xs: 8px;

.gap-xs {
  margin-left: -$gap-xs / 2;
  margin-right: -$gap-xs / 2;

  > div {
    padding-left: $gap-xs / 2;
    padding-right: $gap-xs / 2;
  }
}

$gap-sm: 12px;

.gap-sm {
  margin-left: -$gap-sm / 2;
  margin-right: -$gap-sm / 2;

  > div {
    padding-left: $gap-sm / 2;
    padding-right: $gap-sm / 2;
  }
}

$gap-md: 16px;

$gap-lg: 26px;

.gap-md {
  margin-left: -$gap-md / 2;
  margin-right: -$gap-md / 2;

  > div {
    padding-left: $gap-md / 2;
    padding-right: $gap-md / 2;
  }
}

.gap-lg {
  margin-left: -$gap-md / 2;
  margin-right: -$gap-md / 2;

  > div {
    padding-left: $gap-md / 2;
    padding-right: $gap-md / 2;
  }
  
  @include media-breakpoint-up(xl) {
    margin-left: -$gap-lg / 2;
    margin-right: -$gap-lg / 2;

    > div {
      padding-left: $gap-lg / 2;
      padding-right: $gap-lg / 2;
    }
  }
}

$gap-cards: 20px;

.gap-cards {
  margin-left: -$gap-cards / 2;
  margin-right: -$gap-cards / 2;

  > div {
    padding-left: $gap-cards / 2;
    padding-right: $gap-cards / 2;
  }
}

.tooltip {
  font-size: rem(10);
  line-height: rem(16);
  font-family: $font-alt;
  box-shadow: 10px 30px 60px rgba(56, 87, 104, 0.15);
  margin-left: rem(16) !important;
  border-radius: rem(6) !important;
  padding: rem(7) rem(12) !important;

  .multi-line {
    text-align: left !important;
  }

  &.show {
    opacity: 1 !important;
  }

  .multi-line {
    padding: 0 !important;
  }
}

.bg-lightblue {
  background: #E3F3FF;
}

.mb-md-0 {
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.bg_red {
  background-color: $flamingo !important;
}

.mh-custom {
  min-height: calc(100vh - 175px);
}

.mh-371 {
  min-height: rem(371);
}

// cursor
.cursor-pointer {
  cursor: pointer
}