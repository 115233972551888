@import '@scss/_module';

.wrapper {
  height: rem(180);
  padding: rem(15);
  border: 1px solid $geyser;
  border-radius: 4px;
  cursor: default;

  &.active {
    background: $blacksqueeze;
  }
}

.icon {
  fill: $regentgray;
  &.active {
    fill: $blue;
  }
}

.title {
  margin: rem(10) 0;
}

.description {
  color: $shuttlegray;
}