@import '@scss/_module';

.week_day {
  max-width: rem(50);
  padding: rem(8) 0;
  cursor: default;
  width: 100%;
  text-align: center;
  font-size: rem(12);
  border: 1px solid $geyser;
  color: $regentgray;
  border-radius: 4px;
  text-transform: capitalize;

  &.selected {
    border-color: $blue;
    color: $blue;
    background-color: $lightblue;
  }
}