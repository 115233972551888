@import '@scss/_module';

.wrapper {
  color: black;
  font-weight: 500;
  
}

.list_wrapper {
  height: 24rem;
  overflow-y: auto;
}
