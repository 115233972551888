input:focus,
textarea:focus {
  box-shadow: none !important;
}

label {
  font-weight: 500;
  color: $tundora;
  display: block;
  line-height: 1;
  font-size: rem(16);
  margin-bottom: rem(8);

  &.custom-control-label {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
    line-height: rem(18);
  }
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: $regentgray;
}

.form-group {
  padding-top: rem(12);
}

.form-control {
  padding: 0.6rem 0.7rem;
  height: auto;
  border: 1px solid $input-border-color;
  border-radius: 5px;
  outline: none !important;

  @include media-breakpoint-up(md) {
    padding: 0.7rem 0.8rem;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    border-color: $blue;
  }

  &.is-valid {
    padding-right: $input-padding-x;

    &,
    &:focus {
      border-color: $input-border-color;
      background: $input-bg;
    }
  }
}

textarea.form-control {
  min-height: 141px;
}

.invalid-feedback {
  font-size: rem(12);
  line-height: rem(18);

  .was-validated .form-input:invalid ~ &,
  .form-input.is-invalid ~ & {
    display: block;
  }
}

.form-input {
  margin-bottom: 0;
  border: 1px solid $input-border-color;
  border-radius: 5px;
  font-size: 0.9375rem;

  &:hover {
    border: 1px solid $input-border-color;
  }

  .select__control {
    border: 0;

    .select__value-container {
      padding-top: 0.6rem !important;
      padding-bottom: 0.6rem !important;
      height: auto;
      border-radius: 5px;
      outline: none !important;
      line-height: 1.45;

      @include media-breakpoint-up(md) {
        padding-top: 0.7rem !important;
        padding-bottom: 0.7rem !important;
      }
    }
  }
}

.custom-checkbox {
  text-align: left;
  padding-left: 1.7rem;

  .nav-link {
    padding: 0;
    display: inline;
    font-size: inherit;
  }

  p {
    margin: 0;
  }
}

.private-policy-description {
  margin-top: -5px !important;
  padding-bottom: rem(16);
}

// .was-validated .form-control:valid,
// .form-control.is-valid {
//   background-position: right $input-height-inner-quarter center;
// }

// .form-control.is-valid,
// .is-valid.DateInput_input,
.form-control.is-valid:focus,
.is-valid.DateInput_input:focus {
  border-color: $blue;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: right $input-height-inner-quarter center;
}

.custom-control-label::before {
  border-color: $input-border-color;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $blue;
  background: $white;
  // margin-top: rem(5);
}

.custom-checkbox .custom-control-label::before {
  // margin-top: rem(5);
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 2.8l2.182 2.4L7 1' stroke='%23009BFF' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E") !important;
  // margin-top: rem(5);
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  width: rem(6);
  height: rem(6);
  margin: rem(5) 0 0 rem(5);
  border-radius: 50%;
  background: $blue;
  // background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 2.8l2.182 2.4L7 1' stroke='%23009BFF' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E") !important;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: $regentgray;
}

.error-message {
  color: $error-message;
  font-size: 1.25rem;
}

.hyper-link-text {
  color: $link-color;
}