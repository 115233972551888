@import '@scss/_module';

.wrapper {
  color: black;
  font-weight: 500;
  
}

.skills_wrapper {
  height: 20rem;
  overflow-y: auto;
}
