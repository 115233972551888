@import '@scss/_module';

.noAuthLayout {
  min-height: 100vh;
  background: $white;
  display: block;

  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.column {
  position: relative;
  display: flex;

  &.left {
    flex-grow: 1;
  }

  &.right {
    display: block;

    @include media-breakpoint-up(lg) {
      display: block;
      width: 42%;
    }
  }
}

