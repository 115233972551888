@import '@scss/_module';

.wrapper {
  display: flex;
  background-color: #E2F3FF;
  border-radius: 0.25rem;
  cursor: default;
}

.text {
  padding: 4px 4px 6px 8px;
  color: #009BFF;
    font-size: 0.75rem;
}

