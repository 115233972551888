@import '@scss/_module';

.joinMindTrustLayout {
  margin: 0 auto;
  max-width: rem(600);
  padding-left: rem(12);
  padding-right: rem(12);
}

.logo {
  margin: rem(24) 0 0 0;
  width: rem(170);
  height: rem(40);
}

.content {
  height: rem(550);
}

