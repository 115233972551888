$color-accent: $blue; // #008489
$color-header: $codgray; // $body-color
$picker-drop-bg: #fff; // #fff
$picker-month-bg: #fff; // #fff
$picker-text-color: $codgray; // 757575
$picker-text-color-disabled: $dovegray;
$picker-date-bg: $picker-month-bg;
$picker-date-bg-hover: $color-accent;
$picker-border-color: $mischka; // #e4e7e7
$picker-btn-color: $white; // #757575
$picker-btn-bg: $color-accent;
$picker-btn-bg-hover: darken($blue, 10);
$picker-btn-border-color: transparent; // $picker-btn-bg
$picker-btn-border-color-hover: $picker-btn-bg-hover;

$picker-date-hover: $color-accent; // 00a699
$picker-date-hover-light: rgba($color-accent, 0.1); // #b2f1ec
$picker-date-active: $color-accent; // #80e8e0
$picker-date-selected: $picker-date-hover-light; // #66e2da
$picker-date-selected-hover: $picker-date-hover; // #33dacd

.DateInput {
  background: transparent !important; // #fff
}
.DateInput__disabled {
  background: transparent; // #f2f2f2
  color: inherit; // #dbdbdb
}
.DateInput_input {
  color: $body-color;
  background-color: $white;
  border-color: $picker-border-color;
}
.DateInput_input__focused,
.DateInput_input:focus {
  // color: rgba($white, 0.6);
  background: $white;
  // border-bottom-color: 0;
}
.DateInput_input__disabled {
  background: #f2f2f2;
}
.DateInput_fangShape {
  fill: $picker-drop-bg;
}
.DateInput_fangStroke {
  stroke: $picker-drop-bg;
  fill: transparent;
}
.DateRangePickerInput {
  background-color: transparent; // #fff
}
.DateRangePickerInput__disabled {
  background: transparent; // #f2f2f2
}
.DateRangePickerInput__withBorder {
  // border: 1px solid #dbdbdb;
  border: none;
}
.DateRangePickerInput_arrow {
  color: $picker-text-color;
}
.DateRangePickerInput_arrow_svg {
  fill: $white;
}
.DateRangePickerInput_clearDates {
  color: inherit;
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
}
.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
}
.DateRangePickerInput_calendarIcon {
  color: inherit;
}
.DateRangePickerInput_calendarIcon_svg {
  fill: $white;
}
.DateRangePicker_picker {
  z-index: 1000;
  background-color: $white;
}
.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
}
.DateRangePicker_picker__fullScreenPortal {
  background-color: $white;
}
.DateRangePicker_closeButton {
  color: inherit;
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
}
.DateRangePicker_closeButton_svg {
  fill: #cacccd;
}

// styles

.DateRangePicker {
  display: block;
  z-index: 21;
}

.DateRangePickerInput {
  background: transparent;
  width: 100%;

  &_1 {
    border: 0;
    border-radius: 0;
  }
}

.DateInput {
  position: relative;
  width: rem(93);
  // width: calc((100% - 1.5rem) / 2);

  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 50%;
  //   right: 0.625rem;
  //   width: 1.125rem;
  //   height: 1.125rem;
  //   margin-top: -0.625rem;
  //   overflow: hidden;
  //   background-image: $calendar-icon;
  //   background-repeat: no-repeat;

  //   @include media-breakpoint-down(xs) {
  //     right: 0.3rem;
  //     width: 1rem;
  //     height: 1rem;
  //     margin-top: -0.5625rem;
  //     background-size: contain;
  //     background-position: 50% 50%;
  //   }

  //   @include media-breakpoint-up(xl) {
  //     right: 0.25rem;
  //   }
  // }

  &__disabled {
    &,
    & + .DateRangePickerInput_arrow {
      opacity: 0.25;
    }
  }

  @include media-breakpoint-up(xl) {
    // width: calc((100% - 2.875rem) / 2);
  }

  @include media-breakpoint-up(xxl) {
    // width: calc((100% - 2.875rem) / 2);
  }
}

.DateInput_input {
  border-radius: $border-radius;

  @extend .form-control;

  font-size: rem(12);
  line-height: rem(18);
  padding-right: rem(12);
  padding-bottom: rem(11);
  background: $blacksqueeze;

  .bg-custom.card & {
    // @extend .input-sm;
    // @extend .bg-light;
  }

  &__disabled {
    font-style: inherit;
  }

  &.DateInput_input__small {
    height: 2.1875rem;
    line-height: 1.0625;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  // @include media-breakpoint-up(sm) {
  //   padding-right: 2rem;
  // }

  // @include media-breakpoint-up(xl) {
  //   padding-right: 1.6rem;
  // }

  // @include media-breakpoint-up(xxl) {
  //   padding-right: 2rem;
  // }
}

.DateRangePickerInput_arrow {
  color: $shuttlegray;
  // margin: 0 0.5rem;
  width: 2.375rem;
  display: inline-block;
  vertical-align: middle;
  line-height: rem(38);
  text-align: center;

  .DateInput__small ~ & {
    line-height: 2.1875rem;
  }

  &::before {
    content: 'to';
    // width: 0.5rem;
    // height: 1px;
    // overflow: hidden;
    display: inline-block;
    vertical-align: top;
    // background-color: $body-color;
  }

  svg {
    display: none;
  }

  .topbar & {
    &::before {
      display: none;
    }
  }

  @include media-breakpoint-up(xl) {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  @include media-breakpoint-up(xxl) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.CalendarDay {
  font-size: rem(12);
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  border-color: $color-accent;
  background-color: $color-accent;
}

.CalendarDay__selected_span {
  background-color: $color-accent;
  // border-color: $color-accent;
  transition: background-color $animspeed-base $anim-func-base, border-color $animspeed-base $anim-func-base;

  &:active,
  &:hover {
    background-color: $color-accent;
    border-color: $color-accent;
  }
}

// .DayPickerKeyboardShortcuts_show__bottomRight::before {
//   border-right-color: $color-accent;
// }

.DateInput_fang {
  margin-top: 1px;
}

// colors

.PresetDateRangePicker_button {
  border-color: $picker-date-hover;
  color: $picker-date-hover;
}
.PresetDateRangePicker_button__selected {
  color: $white;
  background: $picker-date-hover;
}
.SingleDatePickerInput {
  background-color: $white;
}
.SingleDatePickerInput__withBorder {
  border-color: #dbdbdb;
}
.SingleDatePickerInput__disabled {
  background-color: #f2f2f2;
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
}
.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
}
.SingleDatePicker_picker {
  background-color: $white;
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
}
.SingleDatePicker_picker__fullScreenPortal {
  background-color: $white;
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
}
.SingleDatePicker_closeButton_svg {
  fill: #cacccd;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before,
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before,
.DayPickerKeyboardShortcuts_show__topRight::before,
.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right-color: transparent; // $color-accent
}
.DayPickerKeyboardShortcuts_show__topLeft::before,
.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left-color: $color-accent;
}
.DayPickerKeyboardShortcuts_showSpan {
  color: $blue;
  font-weight: 700;
}
.DayPickerKeyboardShortcuts_panel {
  background: $white;
  border-color: #dbdbdb;
}
.DayPickerKeyboardShortcuts_close {
  top: 26px;
  height: 15px;
  padding: 0;

  &:focus {
    outline: none;
  }
}
.DayPickerKeyboardShortcuts_closeSvg {
  fill: $picker-text-color; // #cacccd
  vertical-align: top;
}
button:hover,
button:focus {
  .DayPickerKeyboardShortcuts_closeSvg {
    fill: $color-header;
  }
}
.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a;
}
.CalendarDay__default {
  border: none !important;
  // border-color: $picker-border-color;
  color: $picker-text-color;
  background: $picker-date-bg;
}
.CalendarDay__default:hover {
  background: $color-accent;
  // border-color: $color-accent;
  color: $white;
}
.CalendarDay__hovered_offset {
  background: #f4f5f5;
  // border-color: $picker-border-color;
  color: inherit;
}
.CalendarDay__outside {
  background: $white;
  color: $picker-text-color;
}
.CalendarDay__blocked_minimum_nights {
  background: $white;
  // border-color: #eceeee;
  color: #cacccd;
}
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: $white;
  color: #cacccd;
}
.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: $picker-text-color;
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: $picker-text-color;
}
.CalendarDay__selected_span {
  background: $picker-date-selected;
  // border-color: $color-accent;
  // color: $white;
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: $picker-date-selected-hover;
  // border-color: $picker-date-selected-hover;
  color: $white;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $picker-date-hover;
  // border-color: $picker-date-hover;
  color: $white;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: $picker-date-hover-light;
  // border-color: $color-accent;
  color: inherit;
}
.CalendarDay__hovered_span:active {
  background: $picker-date-active;
  // border-color: $picker-date-active;
  color: $white;
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  // border-color: #cacccd;
  color: #82888a;
}
.CalendarDay__blocked_out_of_range {
  color: $picker-text-color-disabled;
  cursor: pointer;
}
// .CalendarDay__blocked_out_of_range,
// .CalendarDay__blocked_out_of_range:active,
// .CalendarDay__blocked_out_of_range:hover {
  // background: $picker-date-bg;
  // border-color: $picker-border-color;
  // color: $picker-text-color-disabled;
  // opacity: 0.6;
// }
.CalendarDay__hovered_start_first_possible_end {
  background: #f00;
  // border-color: #eceeee;
}
.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  border-color: $picker-border-color;
}
.CalendarMonth_caption {
  color: $color-header;
  font-weight: 600;
  padding-bottom: 50px; // 37px

  strong {
    font-weight: inherit;
  }
}
.DayPicker,
.DateRangePicker_picker,
.CalendarMonthGrid,
.DayPicker__horizontal,
.DayPicker_transitionContainer {
  border-radius: 0;
  background: $picker-drop-bg;
}
.CalendarMonth {
  background: $picker-month-bg;
}
.DayPicker__withBorder {
  border-radius: 0;
}
.DayPickerNavigation_button__default {
  border-color: $picker-btn-border-color;
  background-color: $picker-btn-bg;
  color: $picker-btn-color;
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border-color: $picker-btn-bg-hover;
}
.DayPickerNavigation_button__default:active {
  background: $picker-btn-bg-hover;
}
.DayPickerNavigation_button__disabled {
  opacity: 0.25;
}
.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  opacity: 0.25;
}
.DayPickerNavigation_button__verticalDefault {
  background: $picker-btn-bg;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}
.DayPickerNavigation_svg__horizontal {
  fill: $picker-btn-color;
}
.DayPickerNavigation_svg__vertical {
  fill: $picker-btn-color;
}
.DayPickerNavigation_svg__disabled {
  opacity: 0.25;
}
.DayPicker__withBorder {
  // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  box-shadow: $dropdown-box-shadow;
}
.DayPicker_weekHeader,
.DayPickerKeyboardShortcuts_title,
.KeyboardShortcutRow_key {
  color: $color-header;
}

.DayPicker_weekHeader {
  padding-bottom: 6px !important;

  &::after {
    content: "";
    position: absolute;
    left: 13px;
    right: 13px;
    bottom: 0;
    height: 1px;
    overflow: hidden;
    background: $geyser;
  }
}
.DayPicker_weekHeader__verticalScrollable {
  // border-bottom-color: #dbdbdb;
  // background: $white;
}

.DayPickerKeyboardShortcuts_list {
  li {
    margin-top: 0.5rem;
  }
}

// buttons
.DayPickerNavigation_leftButton__horizontalDefault {
  background-color: transparent;
  transition: background-color $animspeed-base $anim-func-base, border-color $animspeed-base $anim-func-base;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 10px;
    margin: -5px 0 0 -3px;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.354.69L6 1.38 4.301 3.192 2.603 5.007 4.292 6.81c.93.992 1.69 1.81 1.69 1.817 0 .017-1.27 1.373-1.286 1.373-.007 0-1.066-1.125-2.354-2.5L0 5l2.342-2.5C3.63 1.125 4.689 0 4.696 0c.007 0 .303.31.658.69z' fill='%23#{$icon-color-secondary}'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transition: opacity $animspeed-base $anim-func-base;
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.354.69L6 1.38 4.301 3.192 2.603 5.007 4.292 6.81c.93.992 1.69 1.81 1.69 1.817 0 .017-1.27 1.373-1.286 1.373-.007 0-1.066-1.125-2.354-2.5L0 5l2.342-2.5C3.63 1.125 4.689 0 4.696 0c.007 0 .303.31.658.69z' fill='%23#{$icon-color-base}'/%3E%3C/svg%3E");
    opacity: 0;
  }

  svg {
    opacity: 0;
  }

  &:hover,
  &:focus {
    background: $blacksqueeze;
    border-color: $blacksqueeze;

    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }
  }
}

.DayPickerNavigation_rightButton__horizontalDefault {
  background-color: transparent;
  transition: background-color $animspeed-base $anim-func-base, border-color $animspeed-base $anim-func-base;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 10px;
    margin: -5px 0 0 -3px;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.646 9.31L0 8.62l1.699-1.813 1.698-1.814L1.708 3.19C.778 2.198.018 1.38.018 1.373.018 1.356 1.288 0 1.304 0c.007 0 1.066 1.125 2.354 2.5L6 5 3.658 7.5A325.855 325.855 0 011.304 10c-.007 0-.303-.31-.658-.69z' fill='%23#{$icon-color-secondary}'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transition: opacity $animspeed-base $anim-func-base;
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.646 9.31L0 8.62l1.699-1.813 1.698-1.814L1.708 3.19C.778 2.198.018 1.38.018 1.373.018 1.356 1.288 0 1.304 0c.007 0 1.066 1.125 2.354 2.5L6 5 3.658 7.5A325.855 325.855 0 011.304 10c-.007 0-.303-.31-.658-.69z' fill='%23#{$icon-color-base}'/%3E%3C/svg%3E");
    opacity: 0;
  }

  svg {
    opacity: 0;
  }

  &:hover,
  &:focus {
    background: $blacksqueeze;
    border-color: $blacksqueeze;

    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }
  }
}

// SingleDatePicker
.SingleDatePicker {
  display: block;

  .DateInput {
    width: 100%;
  }

  .DateInput_input {
    background: $white;
  }
}

.SingleDatePickerInput {
  display: block;
}

.SingleDatePickerInput {
  border-width: 0;
}

// custom datepicker
.custom-datepicker {
  .form-input {
    border: 0;

    &:hover {
      border: 0;
    }
  }

  // fix z-index styles
  .CalendarMonth,
  .DayPicker,
  .DateRangePicker_picker,
  .CalendarMonthGrid,
  .DayPicker__horizontal,
  .DayPicker_transitionContainer {
    background: transparent;
  }

  .DayPicker_focusRegion {
    position: relative;
    z-index: 5;
  }

  // fix arrow
  .DateInput_fang {
    margin-top: 2px;
  }
  
  .DateInput_fangStroke {
    stroke: $geyser;
  }
  
  .DayPicker {
    border-radius: 10px;
    border: 1px solid $geyser;
  }

  .CalendarMonth_caption {
    & > * {
      display: flex;
      justify-content: center;
    }
  }

  .select__control {
    border: 0;
  }

  .select-container {
    margin-top: -4px;
    margin-bottom: 0;
  }
  
  .select__value-container {
    min-height: auto;
    padding: 0 4px !important;
  }

  .select-month .select__single-value {
    font-weight: 600;
    max-width: fit-content;
  }

  .select-year {
    width: 63px !important;
  }

  .select__control {
    min-height: 32px;
  }

  .select__single-value {
    font-size: 16px !important;
    line-height: 1;
  }

  .select__option {
    font-weight: 400;
    font-size: 14px;
    padding: 3px 4px 4px;
  }

  .select__menu {
    left: auto;
    right: -9px;
    width: calc(100% + 8px);
    border-radius: 10px !important;
    border-top: 1px solid $select-input-border-color;

    &::before {
      content: "";
      position: absolute;
      right: 8px;
      bottom: 100%;
      width: 20px;
      height: 10px;
      overflow: hidden;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' role='presentation' focusable='false' class='DateInput_fang DateInput_fang_1' style='top: 55px;'%3E%3Cpath class='DateInput_fangShape DateInput_fangShape_1' d='M0,10 20,10 10,0z' fill='%23ffffff' stroke='%23D5DFE6'%3E%3C/path%3E%3Cpath class='DateInput_fangStroke DateInput_fangStroke_1' d='M0,10 10,0 20,10' fill='%23ffffff'%3E%3C/path%3E%3C/svg%3E");
    }
  }

  .select__menu-list {
    max-height: 186px;
    padding: 0;
  }

  .select__indicator {
    right: 6px !important;
    margin-left: auto !important;
  }
}

.select-flex {
  width: auto !important;
  

  .select__value-container {
    white-space: nowrap;
  }

  .select__control {
    padding-right: 11px;
  }

  .select__single-value {
    position: static;
    transform: translateY(0);
  }

  .select__indicator {
    margin-top: -1px !important;
  }

  input[type='text'] {
    position: absolute;
    top: 4px;
    left: 6px;
  }

  .select__menu {
    width: auto;
    min-width: calc(100% + 8px);
  }
}