@import '@scss/_module';

.selected_item {
  margin-right: 4px;
  background: $blacksqueeze;
  border: 1px solid #D5DFE6;

  > div {
    color: $black;
  }
}

.form_container {
  // overflow: auto;
  // max-height: rem(400);
}

.add_more {
  padding-top: 4px;
  padding-bottom: 4px;
}