@import '@scss/_module';

.button_group {
  button {
    padding: 5px 8px !important;
  }

  button {
    border-right: none;
    border-left: none;
  }

  button:first-child {
    border-left: 1px solid $geyser;
  }

  button:last-child {
    border-right: 1px solid $geyser;
  }

  margin-right: 5px;
}

.add_more {
  padding-top: 4px;
  padding-bottom: 4px;
}

.label {
  margin: rem(12) rem(10) 0 0;
}