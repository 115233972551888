@import '@scss/_module';

.MultipleSelect {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.selected {
  margin-bottom: 1rem;
}

.selected_items {
  display: flex;
  flex-wrap: wrap;
  max-height: rem(58);
  overflow-y: auto;

  > div {
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
  }
}

.list_wrapper {
  flex: 1 0 auto;
  margin-top: 1rem;
}

.label {
  margin-bottom: 0.5rem;
}

.scroll_wrapper {
  border-bottom: 1px solid $geyser;
  height: 100%;
}

.option_list {
  margin: 0;
  list-style: none;
  padding: 0;
  padding-right: 15px;

  li {
    color: $codgray;
    font-weight: 500;
    font-size: rem(12);
    line-height: 1;
    padding: rem(14) 0;
    border-bottom: 1px solid $geyser;
  
    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  
    &.selected {
      &::before {
        content: "";
        width: rem(11);
        height: rem(9);
        margin-right: rem(6);
        display: inline-block;
        vertical-align: rem(-1);
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.571L4.273 7 10 1' stroke='%23009BFF' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
    }
  }
}


.checkbox_list {
  .custom_checkbox {
    padding-top: rem(10);
    padding-bottom: rem(11);
    border-bottom: 1px solid $geyser;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  label {
    color: $codgray;
    font-weight: 500;
    font-size: rem(12);
    line-height: rem(16);
  }
}