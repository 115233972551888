@import '@scss/_module';

.wrapper {
  background: #FFFFFF;
  border: 1px solid #D5DFE6;
  border-radius: 4px;
  color: #566068;
  font-size: 13px;
  line-height: 13px;
}

.skill_name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.icon {
  stroke: #7F8B96;
}

.year_input {
  padding: 0 5px;
  width: rem(30);
  height: rem(28);
}